import React, {useState} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import axios from 'axios';
import Layout from '../../components/layout';
import Modal from '../../components/modal';
import './styles.scss';

const ContactPage = () => {
  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    isModalOpen: false,
    error: false,
    errorMessage: '',
  });

  const submitHandler = async e => {
    e.preventDefault();
    setLoading(true);
    const {data} = await axios({
      method: 'post',
      url: 'https://api.hexometer.com/v2/ql',
      data: {
        operationName: 'ContactFormSubmit',
        variables: {
          name: formData.name,
          email: formData.email,
          subject: `Candidate Checker: ${formData.subject}`,
          message: JSON.stringify(formData.message),
          emailTo: 'support@candidatechecker.io',
          productType: 'candidatechecker',
        },
        query:
          'mutation ContactFormSubmit($name: String!, $email: String!, $subject: String!, $message: String!, $emailTo: String, $productType: String) {\n  contactFormSubmit(name: $name, email: $email, subject: $subject, message: $message, emailTo: $emailTo, productType: $productType) {\n    error\n    message\n    __typename\n  }\n}\n',
      },
    });
    setLoading(false);
    if (data && data.data && data.data.contactFormSubmit && data.data.contactFormSubmit.error) {
      setFormData({
        ...formData,
        error: true,
        errorMessage: t('error-message'),
      });
    } else {
      setFormData({
        ...formData,
        isModalOpen: !formData.isModalOpen,
        error: false,
        errorMessage: '',
        name: '',
        email: '',
        subject: '',
        message: '',
      });
    }
  };

  const changeHandler = event => {
    const target = event.target;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const modalToggle = async () => {
    await setFormData({
      ...formData,
      isModalOpen: !formData.isModalOpen,
    });
    // toggleLastURL()
  };

  return (
    <Layout
      title={t('contact-page-title')}
      small
      showLogo
      metaName={'description'}
      metaContent={'Do you have any questions or suggestions for Candidatechecker? Contact us and we will be there in minutes with all the necessary info you neeed.'}
    >
      <Modal
        isOpen={formData.isModalOpen}
        modalToggle={modalToggle}
        title={t('success-message')}
        onButtonClick={modalToggle}
        buttonText={t('close')}
      />
      <div className="contact-block">
        <div className="main container">
          <div className="page-hero">
            <h1 className="page-title">{t('contact-page-h1')}</h1>
          </div>
          <form className="contact_form_box" onSubmit={e => !loading && submitHandler(e)}>
            <div className="row">
              <div className="col-lg-6">
                <input
                  required
                  type="text"
                  className={`input ${i18n.language === 'ar' ? 'typing-rl' : ''}`}
                  placeholder={t('your-name')}
                  name="name"
                  value={formData.name}
                  onChange={changeHandler}
                />
              </div>
              <div className="col-lg-6">
                <input
                  required
                  type="text"
                  className={`input ${i18n.language === 'ar' ? 'typing-rl' : ''}`}
                  placeholder={t('your-email')}
                  name="email"
                  value={formData.email}
                  onChange={changeHandler}
                />
              </div>
              <div className="col-lg-12">
                <input
                  required
                  type="text"
                  className={`input ${i18n.language === 'ar' ? 'typing-rl' : ''}`}
                  placeholder={t('your-subject')}
                  name="subject"
                  value={formData.subject}
                  onChange={changeHandler}
                />
              </div>
              <div className="col-lg-12">
                <textarea
                  required
                  className="textarea"
                  rows={5}
                  onChange={changeHandler}
                  name="message"
                  placeholder={t('type-message-here')}
                  value={formData.message}
                />
              </div>
              <div className="col-lg-12 footer-block">
                <button className="button" disabled={loading}>
                  {t('send')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
